import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from "react-bootstrap"

export default function BlogPost({ data }) {
    const post = data.allWpPost.nodes[0]
    console.log(post)
    return (
        <Layout>
        <br></br>
            <Container>
                <h1>{post.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: post.content }} />
            </Container>
        </Layout>
    )
}
export const query = graphql`
  query($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
      }
    }
  }
`
